import React, { useContext } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Calendly from "elements/Calendly"
import Container from "layout/Container"

import {
  GATSBY_PBM_ZENDESK_SUBDOMAIN,
  GATSBY_PBM_ZENDESK_API_KEY,
  GATSBY_PBM_ZENDESK_EMAIL,
} from "gatsby-env-variables"
import { AppContext } from "../../../../../context/AppContext"
import { sendToZendesk } from "../../../../../services/zendeskService"
import { getZendeskTemplateTeleconsult } from "../../../services/getZendeskTemplate"

const BookSchedule = ({ pageContext }) => {
  const { state } = useContext(AppContext)
  const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST] "

  const generateZendeskPayloadTeleconsult = ({ bookingUri }) => {
    let subject = "Teleconsult Form"
    let tags = ["teleconsult"]
    if (TEST_ENV) tags = [...tags, "test"]

    return {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${state?.updatedPrescription?.firstName} ${state?.updatedPrescription?.lastName}`,
        email: state?.updatedPrescription?.email,
      },
      comment: {
        body: getZendeskTemplateTeleconsult({
          values: state?.updatedPrescription,
          bookingUri,
        }),
      },
      tags,
    }
  }

  const handleSubmitTeleconsult = async ({ bookingUri }) => {
    const requestPayload = generateZendeskPayloadTeleconsult({ bookingUri })
    await sendToZendesk(requestPayload, [], null, {
      apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
      apiKey: GATSBY_PBM_ZENDESK_API_KEY,
      apiEmail: GATSBY_PBM_ZENDESK_EMAIL,
    })
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      path="/medensure"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Calendly
          state={state.updatedPrescription}
          callback={async (payload) => {
            let uri = payload?.event?.uri

            await handleSubmitTeleconsult({ bookingUri: uri })
          }}
        />
      </Container>
    </Layout>
  )
}

export default BookSchedule
